.form-control {
    cursor: pointer;
  }
  
  .invalid-field {
    border: 1px solid red;
  }
  
.content-upload{
  flex-grow: 1;
    padding: 20px;
}
h3{
  margin-bottom: 3px;
}
/* Ensure the checkbox and text are on the same line */
.checkbox-container {
  width: 120px;
  display: inline-flex; /* Keep checkbox and text on one line */
  align-items: center; 
  
}

/* Style the checkbox */
.checkbox {
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-bottom: 0 !important; 
}

/* Style the text next to the checkbox */
.checkbox-text {
  height: fit-content;
  width: 200px;
  font-size: 16px;
  color: black;
  vertical-align: middle; 
  line-height: 0.5; /* Tweak line-height to better align with the checkbox */
  cursor: pointer;
  font-weight: bold;
}
