/* gallery.css */

.gallery-wrapper {
  padding: 20px;
  text-align: center;
}

.gallery-heading {
  font-size: 4em;
  padding: 20px;
  margin-bottom: 20px;
  color: #333;
}

.gallery-container {
  margin: auto;
  display: flex; 
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  width: 250px; 
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: rgba(0, 0, 0, 0.5);
}

.gallery-item:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 223, 0, 0.8);
  color: #333;
  text-align: center;
  padding: 5px 0;
  font-size: 1em;
  font-weight: bold;
}