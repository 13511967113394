.project-page {
  width: 100%;
}

.cover-image {
  width: 100%;
  background-size: cover;
  background-position: center;
}

/* Align project name, target, and trees planted on one line */
.project-info-container {
  background-color: white;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 15px;
  margin-top: 15px;
  width: 90%;
  border-radius: 10px;
}

.project-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 5px;
}

.project-name {
  font-weight: bold;
  font-size: 24px; /* Smaller project name */
  margin:0;/* Spacing between name and targets */
}

.tree-targets {
  display: flex;
  gap: 20px;
}

.target-btn {
  background-color: #8bc34a;
  padding: 5px 5px;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.project-description {
  margin-left: 5px;
  margin: 0px 0;
  text-align: left;
}

.content-section {
  width: 90%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.image-gallery {
  width: 90%;
  margin-left: auto;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-right: 10px;
  padding: 30px;
  min-height: 250px;
  border-radius: 10px;
}

.tree-graph {
  width: 50%; /* Adjusted to take more space */
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  margin-left: 10px;
  padding: 30px;
  min-height: 250px;
  border-radius: 10px;
}

.Map-Container{
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Add styles for mobile devices */
@media (max-width: 768px) {
  .content-section {
    flex-direction: column; /* Stack the gallery and chart vertically */
  }

  .image-gallery,
  .tree-graph {
    width: 100%; /* Make both sections take the full width */
    margin-bottom: 20px; /* Add some space between the sections */
      background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
}

/* For very small phones */
@media (max-width: 480px) {
  .project-header {
    flex-direction: column;
    gap: 10px; /* Add space between project name and targets */
  }

  .project-name {
    font-size: 20px; /* Reduce the font size for smaller screens */
  }

  .target-btn {
    font-size: 14px; /* Reduce font size of the target buttons */
  }

  .tree-graph {
    margin-bottom: 15px;
  }
}
