/* Activity Dashboard Css Only for datepicker */
.react-datepicker__close-icon {
  background-color: #F5F5F5;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  align-content: stretch;
  align-items: center;
}

/* View Users */
.show-btn {
  font-weight: bold;
  background-color: #4CAF50;
}

.show-btn:hover {
  font-weight: bold;
  background-color: #45a049;
}

.hide-btn {
  font-weight: bold;
  background-color: #f44336; 
}

.unverified-btn {
  font-weight: bold;
  background-color: #e4f759; 
}

.hide-btn:hover {
  font-weight: bold;
  background-color: #e53935;
}

/* Layout */

.sidebar button.active {
  background-color: #8BC34A;
  color: white;
}


.dashboard-container {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f8f9fa;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .sidebar button {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #E70000;
    color: white;
    border: none;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
  }
  
  .sidebar button:hover {
    background-color: #8BC34A;
  }
  
  .content {
    flex-grow: 1;
    padding: 20px;
  }
  
  .content h2 {
    margin-bottom: 20px;
  }
  
  .content input, .content select, .content button {
    margin-bottom: 10px;
  }
  
  .content ul {
    list-style-type: none;
    padding: 0;
  }

/* Add this to your CSS file */
.pagination-container ul li {
  padding: 0 !important;
  border: none !important;
  margin-bottom: 0 !important;
}

  
  .content ul li {
    padding: 10px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
  }

.controls {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 10px;
  }
  
  .search-bar, .filter-input, .sort-select, .verified-filter {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .search-bar, .filter-input {
    flex: 1;
  }
  
  .sort-select, .verified-filter {
    flex: 0 1 150px;
  }
  
  .verify-selected-button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .verify-selected-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .verify-selected-button svg {
    margin-right: 5px;
  }
  
  /* Posts grid styling */
  .posts-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .post-card {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    
  }
  
  .post-card.verified {
    padding-right: 5px;
    float: left;
    border-color: #28a745;
  }
  
  .post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  
  .post-card h3 {
    margin-top: 10px;
  }
  
  .post-card p {
    margin: 0px 0;
    text-align: center;
  }
  
  .post-address {
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  
  .select-checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .select-checkbox:checked {
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .post-actions {
    display: flex;
  }
  
  .verify-button {
    padding: 8px;
    background-color: #ccc;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .verify-button:not(.verified):hover {
    background-color: #28a745;
    cursor: pointer;
  }
  
  .verify-button.verified {
    background-color: #28a745;
    cursor: default;
  }
  
  .verify-button svg {
    margin-right: 5px;
  }
  
  .delete-button {
    padding: 8px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .delete-button svg {
    margin-right: 5px;
  }
  
  /* General styling for form elements */
  input[type="text"], select {
    width: 100%;
    box-sizing: border-box;
  }
  
  .post-address {
    max-width: 100%;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  


  /*Create User*/
  .create-user-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(9, 9, 9, 0.8);
  }
  
  .create-user-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #191919;
  }
  
  .create-user-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }

  .form-group-date {
    display: flex;
    width: 100%;
  }
  
  .form-group-date label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
    
  .form-input, .form-select {
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;

  }
  
  .form-input:focus, .form-select:focus {
    border-color: #8BC34A;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #8BC34A;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #8BC34A;
  }
  
/*View all Users*/.view-all-users-container {
  padding: 20px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.users-table th,
.users-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.users-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.select-dropdown {
  height: 25px;
  font-size: 12px;
}

.reject-button {
  background-color: #C82333;
  color: white;
  padding: 4px 8px;
  border: none;
  font-size: 12px;
  width: 100%;
  cursor: pointer;
}

.reject-button:hover {
  background-color: #B21F2D;
}

.reject-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.custom-select .ant-select-selector {
  color: black !important;
}

.custom-select .ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.45) !important;
}

.custom-select .ant-select-selection-item {
  color: black !important;
}

.custom-select .ant-select-arrow {
  color: black !important;
}

.ant-select-dropdown {
  color: black !important;
}

.ant-select-item-option-content {
  color: black !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: black !important;
  font-weight: 600;
}

/* REJECT SELECT STYLES */

.react-select__control {
  border-color: #ccc;
  border-radius: 5px;
  min-height: 38px;
}

.react-select__value-container {
  padding: 2px 8px;
}

.react-select__placeholder {
  color: #757575;
}

.react-select__single-value {
  color: #333;
}

.react-select__menu {
  z-index: 2;
}

.post-actions {
  display: flex;
  margin-top: 10px;
}

.verify-button,
.delete-button,
.reject-select {
  flex: 1;
}

.reject-select {
  min-width: 120px;
}