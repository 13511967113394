/* Custom tooltip style */
.custom-Tooltip {
  background-color: rgba(255, 255, 255, 0.8);
  color: #5f5f5f;
  opacity: 50;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  font-size: 8px;
  font-weight: 100;
  text-align: center;
}

/* BlogCard.css */
.search-card-section {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #f8f9fa;
}

.search-card-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.search-card-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: left;
}

.search-card-col {
  flex: 0 0 calc(20% - 10px);
  max-width: calc(20% - 10px);
  margin-bottom: 20px; 
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.search-blogCardItem {
  background-color: #8bc34a;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid #8bc34a;
  height: 560px;
  display: flex;
  flex-direction: column;
  /* min-height: 510px; */
}

.search-blogCardImage {
  width: 100%;
  height: 275px;
  /* min-height: 275px; */
}

.search-card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* max-height: 275px; */
}


.search-blogCardDetails {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.search-card-plantName {
  width: 200px;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0;
  color: #efefef;
  margin-left: 5px;
}



.search-card-detail {
  font-weight: bold;
  font-size: 12px;
  color: #333;
  text-align: left;
  word-break: break-word;
  margin: 0px;
  margin-left: 5px;
}

.search-more-info-btn {
  font-weight: bold;
  color: #333;
}


.search-verified {
  font-size: 15px;
  margin-left: 10px;
}

.search-plantedBy {
  font-weight: bold;
  font-size: 18px;
  color: black;
  text-align: left;
  word-break: break-word;
  margin-left: 5px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .search-card-col {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }

  .search-card-plantName {
    margin-top: 15px;
    width: 200px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    color: #efefef;
    margin-left: 2px;
  }
}

@media (max-width: 600px) {
  .search-card-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .search-card-plantName {
    margin-top: 15px;
    width: 200px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
    color: #efefef;
    margin-left: 2px;
  }
}


.blogCardItem {
  background-color: #8bc34a;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid #8bc34a;
  height: 535px;
  display: flex;
  flex-direction: column;
}



/* CSS FOR SHARE BUTTON */
.share-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.share-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 4px;
}

.share-modal-content input {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.share-modal-content button {
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.share-button {
margin: 10px;
  padding: 0px;
  border: none;
  background-color: #8BC34A;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  margin: 1px;
  cursor: pointer;
  border-radius: 4px;
}

.share-button:hover{
background-color: #8BC34A;
}