.dashboard-container {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Ensures space between the two charts */
}

.armasteel-dashboard {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  max-width: 100%;
  overflow-x: hidden;
}

.armasteel-header {
  background-color: #2c3e50;
  color: white;
  padding: 20px;
  text-align: center;
}

.armasteel-header h1 {
  margin: 0;
  font-size: 24px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 5px;
  grid-column-gap: 5px;
  padding: 20px;
  max-width: 100%;
}

.dashboard-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
}

.dashboard-card h2 {
  margin-top: 0;
  font-size: 18px;
  color: #333;
}

.dashboard-card .value {
  font-size: 36px;
  font-weight: bold;
  color: #4CAF50;
}

.impact-profile .profile-content {
  display: flex;
  align-items: center;
}

.impact-profile .company-logo {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.impact-profile .company-info h3 {
  margin: 0;
  font-size: 24px;
}

.impact-profile .visit-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.plantation-status .status-circle {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.plantation-status .status-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.status-legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.legend-item .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-item .dot.planted {
  background-color: #4CAF50;
}

.legend-item .dot.verified {
  background-color: #FFA000;
}

.legend-item .dot.geolocated {
  background-color: #d30000;
}

.average-weekly-trees .tree-icons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.trees-by-month, .co2-offset-by-month {
  grid-column: span 2;
}

.project-map {
  grid-column: span 4;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.errorContainer {
  text-align: center;
  color: red;
  margin: 20px;
}

.noDataContainer {
  text-align: center;
  color: gray;
  margin: 20px;
}



/* Responsive styles for smaller devices */
@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .trees-by-month, .co2-offset-by-month, .project-map {
    grid-column: span 1;
  }

  .impact-profile .profile-content {
    flex-direction: column;
    text-align: center;
    max-width: 100%;
  }

  .total-trees{
    max-width: 100%;
  }

  .impact-profile .company-logo {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .status-legend {
    flex-wrap: wrap;
  }

  .legend-item {
    margin: 5px;
  }

  .plantation-status .status-circle {
    width: 120px;
    height: 120px;
  }

  .dashboard-card .value {
    font-size: 28px;
  }

  .dashboard-card h2 {
    font-size: 16px;
  }

  .dashboard-card {
    padding: 15px;
  }
}

/* Additional styles for very small screens */
@media (max-width: 480px) {
  .dashboard-grid {
    padding: 5px;
  }


  .dashboard-card {
    padding: 10px;
  }

  .dashboard-card .value {
    font-size: 24px;
  }

  .dashboard-card h2 {
    font-size: 14px;
  }

  .impact-profile .company-logo {
    width: 60px;
    height: 60px;
  }

  .impact-profile .company-info h3 {
    font-size: 20px;
  }

}