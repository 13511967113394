.coordinate-container {
    display: flex;
    flex-direction: row; 
}


/* Media query for smaller screens */
@media (max-width: 600px) {
    .coordinate-container {
        flex-direction: column;
    }
}
