.stats-boxes {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .stat-box {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stat-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .p-value{
    color:black;
  }
  
  .stat-box h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .stat-box p {
    font-size: 1.5em;
    margin: 10px 0 0;
  }
  
  @media (max-width: 768px) {
    .stat-box {
      width: 45%; /* Reduced width for smaller screens */
      margin-bottom: 20px;
    }
  
    h1 {
      font-size: 1.5em;
      text-align: center;
      margin-bottom: 30px;
      word-break: break-word; /* Helps with long words on small screens */
    }
  }
  
  @media (max-width: 480px) {
    .stat-box {
      width: 100%; /* Full width for very small screens */
      margin-bottom: 20px;
    }
  
    h1 {
      font-size: 1.2em; /* Smaller heading */
      text-align: center;
      margin-bottom: 20px;
    }
  
    /* Adjust chart display to stack vertically */
    .chart-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .chart-container div {
      margin-bottom: 20px;
      width: 90%;
    }
  }