.signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 10px;
  box-sizing: border-box;
  gap: 5px;
}

.signup-form {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.signup-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin: 5px;
}

.signup-form-group {
  flex: 0 0 48%;
}

.signup-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #333;
}

.signup-form-group input,
.signup-form-group select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #555;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.signup-form-group input:focus,
.signup-form-group select:focus {
  border-color: #8BC34A;
  outline: none;
}

.signup-button {
  width: 100%;
  padding: 12px;
  background-color: #8BC34A;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.signup-button:hover {
  background-color: #7cb342;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.signup-navigation {
  margin-top: 15px;
  text-align: center;
}

.option-link {
  display: inline-block;
  color: #007bff;
  font-size: 14px;
  text-decoration: none;
  margin-left: 5px;
  font-weight: 600;
}

.option-link:hover {
  text-decoration: underline;
}

.signup-navigation p {
  display: inline-block;
  margin-right: 5px;
  font-size: 14px;
  color: #666;
}

@media (max-width: 600px) {
  .signup-form {
    padding: 20px;
  }

  .form-row {
    flex-direction: column;
  }

  .signup-form-group {
    flex: 0 0 100%;
    margin-bottom: 15px;
    margin: 5px;
  }
}