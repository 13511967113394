.name-and-wallet {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.wallet-info {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  color: #4a4a4a;
}

.wallet-icon {
  margin-left: 280px;
  margin-right: 5px;
  color: #333;
}

.collected-points {
  font-size: medium;
  display: flex;
  color: #333;
  font-weight: bold;
}


.profile-container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .cover-photo-wrapper {
    position: relative;
    height: 150px;
    overflow: hidden;
  }
  
  .cover-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .profile-content {
    padding: 20px;
  }
  
  .profile-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .profile-picture-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
    margin-right: 20px;
    margin-top: -60px;
  }
  
  .profile-picture {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: fill;
    border: 4px solid #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-info h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .user-role {
    font-size: 16px;
    color: #666;
    margin-top: 5px;
  }
  
  .profile-details {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .user-details .detail-item {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
  }
  
  .detail-label {
    color: #333;
    font-weight: bold;
    width: 300px; /* Fixed width for uniform spacing */
  text-align: left; /* Align text to the left */
  }
  
  .detail-value {
    color: #666;
    flex: 1;
  }
  
  .edit-form .form-group {
    margin-bottom: 15px;
  }
  
  .edit-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .profile-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .action-buttons {
    text-align: right;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .edit-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .save-button {
    background-color: #2196F3;
    color: white;
    margin-right: 10px;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .button:hover {
    opacity: 0.9;
  }
    
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .profile-picture-wrapper:hover .image-overlay,
  .cover-photo-wrapper:hover .image-overlay {
    opacity: 1;
  }
  
  .file-input {
    display: none;
  }
  
  .loading, .not-logged-in {
    text-align: center;
    font-size: 20px;
    color: #666;
    padding: 40px;
  }
  
  @media (max-width: 600px) {
    .profile-header {
      flex-direction: column;
      text-align: center;
    }
  
    .profile-picture-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .action-buttons {
      text-align: center;
    }
  }