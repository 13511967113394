/* EditProjectPage.css */

/* General Layout */
.input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Label Style */
.input-container label {
  margin-bottom: 5px;
  color: #333;
}

.edit-project-page {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: 'Arial', sans-serif;
}

/* Project Profile Section */
.project-profile {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cover-pic img,
.profile-pic img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.cover-pic img {
  width: 300px;
  height: 200px;
}

.profile-pic img {
  width: 200px;
  height: 200px;
  margin: 5px;
  border-radius: 50%;
}

/* Project Details Form */
.project-details {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
  align-items: flex-start;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #45a049;
}

/* Gallery Section */
.gallery-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.gallery-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.gallery-image button {
  margin-top: 10px;
  background-color: #ff4c4c;
}

.upload-new-image {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.upload-new-image input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
