.projects-dashboard {
    padding: 20px;
    width: 100%;
    margin: auto;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .btn-create, .btn-delete {
    margin: 10px 10px 20px;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    color: white;
    background-color: #4CAF50;
    border-radius: 5px;
  }
  
  .btn-delete:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .projects-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .projects-table th, .projects-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .projects-table th {
    background-color: #f2f2f2;
  }
  
  .btn-manage {
    padding: 0px 0px;
    background-color: white;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* .btn-manage:hover {
    background-color: #808080;
  } */
  
/* Styles for a more compact, wider modal */
.modal-content {
  margin: auto;
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 900px;
  position: relative;
}

.modal-content .form-row {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 15px;
}

.modal-content .form-row > div {
  flex: 1;
}

.modal-content label {
  font-weight: bolder;
  margin-bottom: 5px;
  display: block;
}

.modal-content input[type="text"],
.modal-content input[type="number"],
.modal-content input[type="file"],
.modal-content textarea,
.modal-content .react-select__control {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal-content textarea {
  resize: vertical;
}

.btn-save {
  background-color: #4CAF50;
  color: white;
  width: 50%;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.btn-close {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.modal-content .react-tagsinput {
  border: 1px solid #ccc; 
  border-radius: 4px;
  padding: 5px;
}

.modal-content .react-tagsinput-input {
  border: none;
  width: auto;
}
.profile-photo-input {
  margin-bottom: 10px;
}

.profile-photo-preview {
  max-width: 100px;
  max-height: 100px;
  margin-top: 10px;
}

.cover-photo-input {
  margin-bottom: 10px;
}

.cover-photo-preview {
  max-width: 200px;
  max-height: 100px;
  margin-top: 10px;
}


@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
    padding: 15px;
  }

  .modal-content .form-row {
    flex-direction: column;
  }
}
